<template>
  <v-container
    fluid
    fill-height
    style="height:100%; width: 100%;"
  >
    <v-card
      height="100%"
      width="100%"
      class="pr-2"
    >
      <v-toolbar
        flat
      >
        <v-toolbar-title>
          {{ $t('notifications|notifications') }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          v-if="btnRule"
          :loading="accountLoading"
          x-small
          rounded
          outlined
          color="primary"
          class="mt-1"
          @click="deleteTheRightNotifications()"
        >
          {{ description }}
        </v-btn>
      </v-toolbar>

      <v-tabs
        v-model="tab"
        vertical
      >
        <v-tab
          v-for="(tab, index) in notificationsTabs"
          :key="index"
          class="tabs-text"
        >
          <v-icon left>
            {{ tab.icon }}
          </v-icon>
          {{ tab.text }}
          <v-badge
            v-if="!!badgeRule(index)"
            bordered
            color="error"
            overlap
            :content="getNotifAmount(index)"
            size="15"
            offset-x="15"
            offset-y="20"
            class="ml-4"
          >
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="!tab.isInvitationsTab ? on : ''"
                  @click="!tab.isInvitationsTab ? markTheTabNotificationsAsRead(index) : ''"
                >
                  <v-icon>mdi-bell</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('notifications|mark_all_read') }}</span>
            </v-tooltip>
          </v-badge>
        </v-tab>
        <v-tab-item
          v-for="(tab, i) in notificationsTabs"
          :key="i"
        >
          <NotificationsTabs
            :is-all-notifications-tab="tab.isAllNotificationsTab"
            :is-invitations-tab="tab.isInvitationsTab"
            :is-document-updates-tab="tab.isDocumentUpdatesTab"
            :is-cases-updates-tab="tab.isCasesUpdatesTab"
            :is-client-updates-tab="tab.isClientUpdatesTab"
            :is-templates-updates-tab="tab.isTemplatesUpdatesTab"
            :is-members-updates-tab="tab.isMembersUpdatesTab"
            :all-my-notifications="account.myNotifications"
            :filtered-documents-notifications="filteredDocumentsNotifications"
            :invitations="invitations"
            :filtered-cases-notifications="filteredCasesNotifications"
            :filtered-clients-notifications="filteredClientsNotifications"
            :filtered-templates-notifications="filteredTemplatesNotifications"
            @open-invitations-info-dialog="openInfoInvitationsDialog"
            @open-info-dialog="onOpenInfoDialog"
            @mark-as-read="markSomeNotificationAsRead"
          />
        </v-tab-item>
      </v-tabs>
      <InfoDialog
        ref="InfoDialog"
      />
      <v-dialog
        v-model="infoInvitationDialog"
        width="500"
      >
        <v-card>
          <v-card-title class="text-h6 grey lighten-2">
            {{ $t('common|more_info') }}
          </v-card-title>

          <v-card-text
            v-if="!isLawFirm"
            class="mt-4"
          >
            {{ $t('mesage|connect_other_account') }}
          </v-card-text>

          <v-card-text
            v-if="isLawFirm"
            class="mt-4"
          >
            {{ $t('mesage|connect_to_law_firm') }}
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              text
              @click="infoInvitationDialog = false"
            >
              {{ $t('actions|close') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>
import { EventBus } from '@/utils/EventBus'
import { mapState, mapActions, mapMutations } from 'vuex'
import { translateNotification } from '@/utils/utils'
import { mdiFileRefreshOutline } from '@mdi/js'
import NotificationsTabs from './NotificationsTabs.vue'
import InfoDialog from './InfoDialog.vue'

export default {
  components: { NotificationsTabs, InfoDialog },
  data: () => ({
    tab: 0,
    incommingNotification: {},
    infoInvitationDialog: false,
    isLawFirm: false
  }),
  computed: {
    ...mapState({
      accountLoading: state => state.account.loading,
      account: state => state.account.account,
      company: state => state.company.company,
      documents: state => state.documents.documents
    }),
    notificationsTabs () {
      let tabs = []
      if (this.account.accountType === 'lawyer') {
        tabs.push({
          text: this.$t('notifications|all_notifications'),
          icon: 'mdi-select-all',
          isAllNotificationsTab: true
        })
        tabs.push({
          text: this.$t('notifications|invitations'),
          icon: 'mdi-account-plus',
          isInvitationsTab: true
        })
        tabs.push({
          text: this.$t('notifications|document_updates'),
          icon: mdiFileRefreshOutline,
          isDocumentUpdatesTab: true
        })
        tabs.push({
          text: this.$t('notifications|case_updates'),
          icon: 'mdi-briefcase-check',
          isCasesUpdatesTab: true
        })
        tabs.push({
          text: this.$t('notifications|client_updates'),
          icon: 'mdi-account',
          isClientUpdatesTab: true
        })
        tabs.push({
          text: this.$t('notifications|template_updates'),
          icon: 'mdi-clipboard-text-outline',
          isTemplatesUpdatesTab: true
        })
        // tabs.push({
        //   text: 'Members updates',
        //   icon: 'mdi-account-convert',
        //   isMembersUpdatesTab: true
        // })
      } else {
        tabs.push({
          text: this.$t('notifications|all_notifications'),
          icon: 'mdi-select-all',
          isAllNotificationsTab: true
        })
        tabs.push({
          text: this.$t('notifications|invitations'),
          icon: 'mdi-account-plus',
          isInvitationsTab: true
        })
        tabs.push({
          text: this.$t('notifications|document_updates'),
          icon: mdiFileRefreshOutline,
          isDocumentUpdatesTab: true
        })
        tabs.push({
          text: this.$t('notifications|case_updates'),
          icon: 'mdi-briefcase-check',
          isCasesUpdatesTab: true
        })
      }
      return tabs
    },
    actualTab () {
      return this.notificationsTabs[this.tab]
    },
    btnRule () {
      let rule
      if (this.actualTab && this.actualTab.isAllNotificationsTab && this.account.myNotifications.length) rule = true
      else if (this.actualTab && this.actualTab.isInvitationsTab && this.invitations.length) rule = false
      else if (this.actualTab && this.actualTab.isDocumentUpdatesTab && this.filteredDocumentsNotifications.length) rule = true
      else if (this.actualTab && this.actualTab.isCasesUpdatesTab && this.filteredCasesNotifications.length) rule = true
      else if (this.actualTab && this.actualTab.isTemplatesUpdatesTab && this.filteredTemplatesNotifications.length) rule = true
      else if (this.actualTab && this.actualTab.isClientUpdatesTab && this.filteredClientsNotifications.length) rule = true
      else if (this.actualTab && this.actualTab.isMembersUpdatesTab && this.filteredMembersNotifications) rule = true
      else rule = false
      return rule
    },
    description () {
      let _description
      if (this.actualTab && this.actualTab.isAllNotificationsTab) _description = this.$t('notifications|clear_all')
      // if (this.actualTab && this.actualTab.isInvitationsTab) _description = this.$t('notifications|clear_invitations')
      if (this.actualTab && this.actualTab.isDocumentUpdatesTab) _description = this.$t('notifications|clear_document')
      if (this.actualTab && this.actualTab.isCasesUpdatesTab) _description = this.$t('notifications|clear_case')
      if (this.actualTab && this.actualTab.isTemplatesUpdatesTab) _description = this.$t('notifications|clear_template')
      if (this.actualTab && this.actualTab.isClientUpdatesTab) _description = this.$t('notifications|clear_client')
      if (this.actualTab && this.actualTab.isMembersUpdatesTab) _description = this.$t('notifications|clear_member')
      return _description
    },
    allActiveNotifications () {
      let allItems = []
      for (let i = 0; i < this.account.myNotifications.length; i++) {
        let n = this.account.myNotifications[i]
        if (n.status_read === 'false') {
          translateNotification(n)
          allItems.push(n)
        }
      }
      for (let i = 0; i < this.account.invitations.length; i++) {
        const invitation = this.account.invitations[i]
        if (invitation.status === 'pending') {
          allItems.push(invitation)
        }
      }
      return allItems.slice().reverse()
    },
    filteredDocumentsNotifications () {
      let notifications = []
      for (let i = 0; i < this.account.myNotifications.length; i++) {
        let n = this.account.myNotifications[i]
        if (n.object_type === 'Documents') {
          translateNotification(n)
          notifications.push(n)
        }
      }
      return notifications.slice().reverse()
    },
    filteredCasesNotifications () {
      let notifications = []
      for (let i = 0; i < this.account.myNotifications.length; i++) {
        let n = this.account.myNotifications[i]
        if (n.object_type === 'Case') {
          translateNotification(n)
          notifications.push(n)
        }
      }
      return notifications.slice().reverse()
    },
    filteredClientsNotifications () {
      let notifications = []
      for (let i = 0; i < this.account.myNotifications.length; i++) {
        let n = this.account.myNotifications[i]
        if (n.object_type === 'Client') {
          translateNotification(n)
          notifications.push(n)
        }
      }
      return notifications.slice().reverse()
    },
    filteredTemplatesNotifications () {
      let notifications = []
      for (let i = 0; i < this.account.myNotifications.length; i++) {
        let n = this.account.myNotifications[i]
        if (n.object_type === 'Template') {
          translateNotification(n)
          notifications.push(n)
        }
      }
      return notifications.slice().reverse()
    },
    filteredMembersNotifications () {
      let notifications = []
      for (let i = 0; i < this.account.myNotifications.length; i++) {
        let n = this.account.myNotifications[i]
        if (n.object_type === 'Company') {
          translateNotification(n)
          notifications.push(n)
        }
      }
      return notifications.slice().reverse()
    },
    invitations () {
      let allInvitations = []
      this.account.invitations.forEach(inv => {
        allInvitations.push(inv)
      })
      if (this.company) {
        this.company.invitations.forEach(inv => {
          allInvitations.push(inv)
        })
        this.company.groups.forEach(group => {
          group.invitations.forEach(inv => {
            allInvitations.push(inv)
          })
        })
      }
      return allInvitations.slice().reverse()
    },
    pendingInvitations () {
      let invitationsStillActive = []
      this.invitations.forEach(invitation => {
        if (invitation.status === 'pending') {
          invitationsStillActive.push(invitation)
        }
      })
      return invitationsStillActive
    },
    newDocumentNotifications () {
      return this.filteredDocumentsNotifications.filter(notif => notif.read_status === false)
    }
  },
  created () {
    this.toggleDrawer(true)
    EventBus.$on('incomming_notification', this.onHandleNotification)
  },
  beforeDestroy () {
    EventBus.$off('incomming_notification', this.onHandleNotification)
  },
  methods: {
    ...mapActions({
      addToast: 'toasts/addToast',
      deleteMyNotification: 'account/deleteMyNotification',
      deleteAllMyNotifications: 'account/deleteAllMyNotifications',
      deleteSomeNotifications: 'account/deleteSomeNotifications',
      markNotificationsAsRead: 'account/markNotificationsAsRead',
      fetchAccount: 'account/fetchAccount',
      getDocument: 'documents/getDocument'
    }),
    ...mapMutations({
      toggleDrawer: 'app/toggleDrawer'
    }),
    openInfoInvitationsDialog (input) {
      if (input && input === 'law-firm') this.isLawFirm = true
      this.infoInvitationDialog = true
    },
    badgeRule (tabIndex) {
      const filterArr = (arr) => {
        return arr.filter(notif => notif.status_read === false).length
      }
      let rule
      const tab = this.notificationsTabs[tabIndex]
      if (tab.isAllNotificationsTab && this.account.myNotifications.length && filterArr(this.account.myNotifications)) rule = true
      else if (tab.isInvitationsTab && this.pendingInvitations.length) rule = true
      else if (tab.isDocumentUpdatesTab && this.filteredDocumentsNotifications && filterArr(this.filteredDocumentsNotifications)) rule = true
      else if (tab.isCasesUpdatesTab && this.filteredCasesNotifications.length && filterArr(this.filteredCasesNotifications)) rule = true
      else if (tab.isTemplatesUpdatesTab && this.filteredTemplatesNotifications.length && filterArr(this.filteredTemplatesNotifications)) rule = true
      else if (tab.isClientUpdatesTab && this.filteredClientsNotifications.length && filterArr(this.filteredClientsNotifications)) rule = true
      else if (tab.isMembersUpdatesTab && this.filteredMembersNotifications.length) rule = true
      else rule = false
      return rule
    },
    getNotifAmount (tabIndex) {
      const tab = this.notificationsTabs[tabIndex]
      if (tab.isAllNotificationsTab && this.account.myNotifications.length) return this.account.myNotifications.filter(notif => notif.status_read === false).length
      if (tab.isInvitationsTab && this.pendingInvitations.length) return this.pendingInvitations.length
      if (tab.isDocumentUpdatesTab && this.filteredDocumentsNotifications.length) return this.filteredDocumentsNotifications.filter(notif => notif.status_read === false).length
      if (tab.isCasesUpdatesTab && this.filteredCasesNotifications.length) return this.filteredCasesNotifications.filter(notif => notif.status_read === false).length
      if (tab.isTemplatesUpdatesTab && this.filteredTemplatesNotifications.length) return this.filteredTemplatesNotifications.filter(notif => notif.status_read === false).length
      if (tab.isClientUpdatesTab && this.filteredClientsNotifications.length) return this.filteredClientsNotifications.filter(notif => notif.status_read === false).length
      if (tab.isMembersUpdatesTab && this.filteredMembersNotifications.length) return this.filteredMembersNotifications.filter(notif => notif.status_read === false).length
    },
    onHandleNotification (notification) {
      if (notification.object_type === 'Documents') {
        const doc = this.documents.filter(d => d._id === notification.relevant_object)
        return doc
      }
    },
    onOpenInfoDialog (notification) {
      this.$refs.InfoDialog.dialog = true
      this.$refs.InfoDialog.notification = notification
    },
    deleteTheRightNotifications () {
      if (this.actualTab && this.actualTab.isAllNotificationsTab) this.clearAllNotifications()
      if (this.actualTab && this.actualTab.isDocumentUpdatesTab) this.clearSomeNotifications(this.filteredDocumentsNotifications)
      if (this.actualTab && this.actualTab.isCasesUpdatesTab) this.clearSomeNotifications(this.filteredCasesNotifications)
      if (this.actualTab && this.actualTab.isTemplatesUpdatesTab) this.clearSomeNotifications(this.filteredTemplatesNotifications)
      if (this.actualTab && this.actualTab.isClientUpdatesTab) this.clearSomeNotifications(this.filteredClientsNotifications)
      if (this.actualTab && this.actualTab.isMembersUpdatesTab) this.clearSomeNotifications(this.filteredMembersNotifications)

      // if (this.actualTab && this.actualTab.isInvitationsTab) this.clearInvitations()
    },
    async markTheTabNotificationsAsRead (index) {
      let notifications = []
      const tab = this.notificationsTabs[index]
      if (tab.isAllNotificationsTab && this.account.myNotifications.length) notifications = this.account.myNotifications
      if (tab.isDocumentUpdatesTab && this.filteredDocumentsNotifications.length) notifications = this.filteredDocumentsNotifications
      if (tab.isCasesUpdatesTab && this.filteredCasesNotifications.length) notifications = this.filteredCasesNotifications
      if (tab.isTemplatesUpdatesTab && this.filteredTemplatesNotifications.length) notifications = this.filteredTemplatesNotifications
      if (tab.isClientUpdatesTab && this.filteredClientsNotifications.length) notifications = this.filteredClientsNotifications
      if (tab.isMembersUpdatesTab && this.filteredMembersNotifications.length) notifications = this.filteredMembersNotifications
      const onlyNotificationIds = notifications.map((item) => {
        return item._id
      })
      const _id = this.account._id
      const payload = {
        notifications: onlyNotificationIds,
        markAsRead: true
      }
      try {
        await this.markNotificationsAsRead({ _id, payload })
      } catch (error) {
        console.error(error, 'error')
      }
    },
    async markSomeNotificationAsRead (notification) {
      const notifId = [notification._id]
      const _id = this.account._id
      const payload = {
        notifications: notifId,
        markAsRead: true
      }
      try {
        await this.markNotificationsAsRead({ _id, payload })
      } catch (error) {
        console.error(error, 'error')
      }
    },
    async clearAllNotifications () {
      const res = await this.$dialog.confirm({
        title: this.$t('common|warning'),
        text: this.$t('warning|you_want_to_delete_all_notifications')
      })
      if (!res) return
      try {
        await this.deleteAllMyNotifications()
      } catch (error) {
        console.error(error, 'error')
      }
    },
    async clearSomeNotifications (notifications) {
      const res = await this.$dialog.confirm({
        title: this.$t('common|warning'),
        text: this.$t('warning|you_want_to_delete_this_notifications')
      })
      if (!res) return
      const onlyNotificationIds = notifications.map((item) => {
        return item._id
      })
      const _id = this.account._id
      const payload = {
        notifications: onlyNotificationIds
      }
      try {
        await this.deleteSomeNotifications({ _id, payload })
      } catch (error) {
        console.error(error, 'error')
      }
    },
    async deleteNotications (notificationIds) {
      const payload = {
        notificationsToBeDeleted: notificationIds
      }
      try {
        await this.deleteSomeNotifications(payload)
      } catch (error) {
        console.error(error, 'error')
      }
    },
    async deleteNotification (notification) {
      await this.$dialog.confirm({
        title: this.$t('common|warning'),
        text: this.$t('warning|you_want_to_delete_this_notification')
      })
      const payload = {
        myNotifications: notification
      }
      try {
        await this.deleteMyNotification(payload)
      } catch (error) {
        console.error(error, 'error')
      }
    }
  }
}
</script>

<style scoped>
.tabs-text {
  justify-content: flex-start;
}
</style>
