<template>
  <v-dialog
    v-model="dialog"
    width="500"
  >
    <v-card>
      <v-card-title class="text-h6 grey lighten-2">
        More info
      </v-card-title>

      <v-card-text
        v-if="notification && notification.details.error"
        class="mt-4"
      >
        {{ $t(notification.details.error) }}
      </v-card-text>
      <v-card-text
        v-else
        class="mt-4"
      >
        Please upload the document once more or contact the lavvira support
        at support@lavvira.net
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="dialog = false, notification = null"
        >
          close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  data: () => ({
    dialog: false,
    notification: null
  })
}
</script>
